<style>
table.header--viptotrip tr {
  font-size: 12px;
}
table.header--viptotrip thead tr th:nth-child(1) {
  width: 100px;
}
table.header--viptotrip thead tr th:nth-child(2) {
  width: 95px;
}
table.header--viptotrip thead tr th:nth-child(3) {
  width: 30px;
}
table.header--viptotrip thead tr th:nth-child(4) {
  width: 30px;
}
table.header--viptotrip thead tr th:nth-child(5) {
  width: 90px;
}
table.header--viptotrip thead tr th:nth-child(6) {
  width: 80px;
}
table.header--viptotrip thead tr th:nth-child(7) {
  width: 60px;
}
table.header--viptotrip thead tr th:nth-child(8) {
  width: 30px;
}
table.header--viptotrip thead tr th:nth-child(9) {
  width: 115px;
}
table.header--viptotrip thead tr th:nth-child(10) {
  width: auto;
}
table.header--viptotrip thead tr th:nth-child(11) {
  width: auto;
}

table.header--viptotrip tbody tr td:nth-child(11) {
  width: auto;
  font-size: 11px;
  line-height: 1.2;
}
</style>

<template>
  <main class="main2">
    <div
      v-if="viptotrip === undefined || viptotrip.clients === null"
      class="container loader-wrapper"
    >
      <div class="row">
        <div class="col-xs-12 text-center">
          <p class="loading2">{{ $t("default.loading") }}</p>
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row">
        <div class="col-xs-12 col-md-5">
          <input
            type="text"
            class="form-control matb20"
            v-model="search"
            :placeholder="$t('tables.search')"
          />
        </div>
        <div class="col-xs-12 col-md-7">
          <div class="flexWrap">
            <div
              @click="download2"
              class="abtn--c4"
              style="margin-right: 16px; cursor: pointer"
            >
              {{ $t("default.download") }}
            </div>
            <pagination
              v-model="page"
              :total-page="pages"
              size="md"
              align="right"
              boundary-links
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <Table
            v-if="viptotrip"
            class="rwdtable header--viptotrip"
            style="width: 100%"
            :items="viptotrip.clients"
            :headers="headers"
            :fields="fields"
            :search="search"
            :page.sync="page"
            :itemsPerPage="itemsPerPage"
            :pages.sync="pages"
            :defaultSort="{ sortIdx: 9, sortRev: true }"
            @tr:clicked="OPEN_POPUP_CLIENT($event.ix)"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <pagination
            v-model="page"
            :total-page="pages"
            size="md"
            align="right"
            boundary-links
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Table from "../components/Table";
import { FETCH_VIPTOTRIP } from "../store/actions.type";
import { OPEN_POPUP_CLIENT } from "../store/mutations.type";
import exportFromJSON from "export-from-json";
import xlsx from "json-as-xlsx";

export default {
  components: {
    Table,
  },
  data() {
    return {
      fields: [
        "ix",
        "nip",
        "name",
        "accept",
        "target1",
        "target2",
        "amount",
        "amount_points",
        "realisation1",
        "realisation2",
        "fi",
        // 'ph',
        "info",
      ],
      search: "",
      itemsPerPage: 30,
      page: 1,
      pages: 1,
    };
  },
  computed: {
    ...mapGetters(["viptotrip"]),
    headers() {
      return [
        this.$i18n.t("tables.customerNumber"),
        this.$i18n.t("tables.NIP"),
        this.$i18n.t("tables.alias"),
        this.$i18n.t("tables.acceptanceRules"),
        "Target1",
        "Target2",
        this.$i18n.t("tables.purchases"),
        this.$i18n.t("tables.purchasesRecounted"),
        "Realizacja1",
        "Realizacja2",
        this.$i18n.t("tables.branch"),
        // this.$i18n.t("tables.ph"),
        this.$i18n.t("tables.information"),
      ];
    },
  },
  created() {
    this.$store.dispatch(FETCH_VIPTOTRIP);
  },
  methods: {
    ...mapMutations([OPEN_POPUP_CLIENT]),
    download() {
      const reduceItems = this.fields.reduce((accumulator, element, index) => {
        return { ...accumulator, [element]: this.headers[index] };
      }, {});
      const data = this.viptotrip.clients;
      const fields = reduceItems;
      const fileName = `VIP_TO_TRIP_2024`;
      const exportType = exportFromJSON.types.xls;

      exportFromJSON({ data, fileName, fields, exportType });
    },
    download2() {
      let data = [
        {
          sheet: "",
          columns: [
            { label: this.$i18n.t("tables.customerNumber"), value: "ix" },
            { label: this.$i18n.t("tables.NIP"), value: "nip" },
            { label: this.$i18n.t("tables.alias"), value: "name" },
            { label: this.$i18n.t("tables.acceptanceRules"), value: "accept" },
            { label: "Target1", value: "target1" },
            { label: "Target2", value: "target2" },
            {
              label: this.$i18n.t("tables.purchases"),
              value: "amount",
              // eslint-disable-next-line no-useless-escape
              format: "#,##0.00\ [$zł-415]",
            },
            {
              label: this.$i18n.t("tables.purchasesRecounted"),
              value: "amount_points",
              // eslint-disable-next-line no-useless-escape
              format: "#,##0.00\ [$zł-415]",
            },
            { label: "Realizacja1", value: "realisation1" },
            { label: "Realizacja2", value: "realisation2" },
            { label: this.$i18n.t("tables.branch"), value: "fi" },
            // { label: this.$i18n.t("tables.ph"), value: "ph" },
            { label: this.$i18n.t("tables.information"), value: "info" },
          ],
          content: this.viptotrip.clients,
        },
      ];

      let settings = {
        fileName: `VIP_TO_TRIP_2024`,
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
      };
      xlsx(data, settings);
    },
  },
};
</script>
