import Home from "../views/Home.vue";
import Promotion from "../views/Promotion.vue";
import PromotionApp from "../views/PromotionApp.vue";
import ExternalPromotions from "../views/ExternalPromotions.vue";
import ExternalPromotionsViptotrip from "../views/ExternalPromotionsViptotrip231.vue";
import ExternalPromotionsMaxGearSky from "../views/ExternalPromotionsMaxGearSky.vue";
import ExternalPromotionsAPExpert from "../views/ExternalPromotionsAPExpert10.vue";
import ExternalPromotions3spn from "../views/ExternalPromotions3spn.vue";
import ExternalPromotionsWlm from "../views/ExternalPromotionsWlm.vue";
import ExternalPromotionslnmsklep from "../views/ExternalPromotionslnmsklep.vue";
import ExternalPromotionslnmwarsztat from "../views/ExternalPromotionslnmwarsztat.vue";

import MyClients from "../views/MyClients.vue";
import TheSubheaderHome from "../components/TheSubheaderHome";
import TheSubheaderPromotion from "../components/TheSubheaderPromotion";
import TheSubheaderPromotionApp from "../components/TheSubheaderPromotionApp";
import TheSubheaderPromotionMaxGearSky from "../components/TheSubheaderPromotionMaxGearSky";
import TheSubheaderPromotionApexpert from "../components/TheSubheaderPromotionApexpert";
import TheSubheaderPromotion3spn from "../components/TheSubheaderPromotion3spn";
import TheSubheaderPromotionWlm from "../components/TheSubheaderPromotionWlm";
import TheSubheaderPromotionViptotrip from "../components/TheSubheaderPromotionViptotrip";
import TheSubheaderPromotionlnmsklep from "../components/TheSubheaderPromotionlnmsklep";
import TheSubheaderPromotionlnmwarsztat from "../components/TheSubheaderPromotionlnmwarsztat";
import TheSubheaderMyClients from "../components/TheSubheaderMyClients";

import MyBonuses from "../views/MyBonuses.vue";
import TheSubheaderMyBonuses from "../components/TheSubheaderMyBonuses";
import MyBonusesDrg from "../views/MyBonusesDrg.vue";
import TheSubheaderMyBonusesDrg from "../components/TheSubheaderMyBonusesDrg";

export default [
  {
    path: "/",
    components: {
      subheader: TheSubheaderHome,
      view: Home,
    },
  },
  {
    path: "/external-promotions",
    components: {
      subheader: TheSubheaderHome,
      view: ExternalPromotions,
    },
  },
  {
    path: "/external-promotions/viptotrip",
    components: {
      subheader: TheSubheaderPromotionViptotrip,
      view: ExternalPromotionsViptotrip,
    },
  },
  {
    path: "/external-promotions/apexpert",
    components: {
      subheader: TheSubheaderPromotionApexpert,
      view: ExternalPromotionsAPExpert,
    },
  },
  {
    path: "/external-promotions/maxgearsky",
    components: {
      subheader: TheSubheaderPromotionMaxGearSky,
      view: ExternalPromotionsMaxGearSky,
    },
  },
  {
    path: "/external-promotions/3spn",
    components: {
      subheader: TheSubheaderPromotion3spn,
      view: ExternalPromotions3spn,
    },
  },
  {
    path: "/external-promotions/wlm",
    components: {
      subheader: TheSubheaderPromotionWlm,
      view: ExternalPromotionsWlm,
    },
  },
  {
    path: "/external-promotions/lnm-sklep",
    components: {
      subheader: TheSubheaderPromotionlnmsklep,
      view: ExternalPromotionslnmsklep,
    },
  },
  {
    path: "/external-promotions/lnm-warsztat",
    components: {
      subheader: TheSubheaderPromotionlnmwarsztat,
      view: ExternalPromotionslnmwarsztat,
    },
  },
  {
    path: "/promotions/:id",
    components: {
      subheader: TheSubheaderPromotion,
      view: Promotion,
    },
  },
  {
    path: "/promotions/app/:id",
    components: {
      subheader: TheSubheaderPromotionApp,
      view: PromotionApp,
    },
  },
  {
    path: "/my-clients",
    components: {
      subheader: TheSubheaderMyClients,
      view: MyClients,
    },
  },
  {
    path: "/my-bonuses",
    components: {
      subheader: TheSubheaderMyBonuses,
      view: MyBonuses,
    },
  },
  {
    path: "/my-bonuses-branches",
    components: {
      subheader: TheSubheaderMyBonusesDrg,
      view: MyBonusesDrg,
    },
  },
];
