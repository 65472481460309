<template>
<div>
    <modal v-if="myInfo" v-model="isOpen" title="Account" @show="onShow" ref="modal" id="modal-account" size="md" auto-focus>
        <div slot="title">{{ $t("account.title") }}</div>

        <h4><span class="spanlabel">{{ $t("account.branch") }}: </span><strong>{{ myInfo.branch_account_name }}</strong></h4><hr/>
        <h4><strong>{{ myInfo.branch_account_firstname }} {{ myInfo.branch_account_lastname }}</strong></h4>
        <br>
        <p><span class="spanlabel">{{ $t("account.phone") }}: </span><strong>{{ myInfo.branch_account_phone }}</strong></p>
        <p><span class="spanlabel">{{ $t("account.email") }}: </span><strong>{{ myInfo.branch_account_email }}</strong></p>
        <p><span class="spanlabel">{{ $t("account.account") }}: </span><strong>{{ myInfo.branch_account_type }}</strong></p>
        
        
        <div slot="footer">
            <!--<btn @click="isOpen=false">Zamknij</btn>-->
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CLOSE_POPUP_ACCOUNT } from '../store/mutations.type'

export default {
    methods: {
      onShow() {
        // console.log('account')
      }
    },
    computed: {
        ...mapGetters(['isPopupAccountOpen', 'myInfo']),
        isOpen: {
            get() {
                return this.isPopupAccountOpen
            },
            set() {
                this.$store.commit(CLOSE_POPUP_ACCOUNT)
            }
        }
    }
}
</script>