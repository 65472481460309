<template>
  <footer>
    <div class="container">
      <div v-if="myClientsLoading" class="row">
        <div class="col-xs-12 text-center">
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <p class="promoarea" data-competition-name="Kontakt">
            <a href="https://autopartner.com/" target="_blank">{{
              $t("default.site")
            }}</a>
            <a
              href="#"
              class="popboxopen_promoask"
              data-popid="pop_promoask"
              data-title2=""
              >{{ $t("default.contact") }}</a
            >
          </p>
        </div>
        <div class="col-xs-12">
          <p>&copy; Auto Partner SA {{ new Date().getFullYear() }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["myClientsLoading"]),
  },
};
</script>
