export const FETCH_PROMOTIONS = "FETCH_PROMOTIONS";
export const FETCH_PROMOTION = "FETCH_PROMOTION";
export const FETCH_MY_CLIENTS = "FETCH_MY_CLIENTS";
export const FETCH_PP_CLIENTS = "FETCH_PP_CLIENTS";
export const FETCH_PROTOCOL_CLIENTS = "FETCH_PROTOCOL_CLIENTS";
export const FETCH_MY_BONUSES = "FETCH_MY_BONUSES";
export const FETCH_MY_INFO = "FETCH_MY_INFO";
export const FETCH_INIT = "FETCH_INIT";
export const FETCH_MAXGEARSKY = "FETCH_MAXGEARSKY";
export const FETCH_APEXPERT = "FETCH_APEXPERT";
export const FETCH_VIPTOTRIP = "FETCH_VIPTOTRIP";
export const FETCH_wlm = "FETCH_wlm";
export const FETCH_spn3 = "FETCH_spn3";
export const FETCH_lnmwarsztat = "FETCH_lnmwarsztat";
export const FETCH_lnmsklep = "FETCH_lnmsklep";
