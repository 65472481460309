import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import state from "./state";
import VuexPersist from "vuex-persist";
import localForage from "localforage";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  storage: localForage,
  asyncStorage: true,
});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state,
  getters,
  mutations,
  actions,
  plugins: [vuexLocalStorage.plugin],
});
