<template>
  <main class="main1">
    <div class="container">
      <div class="row tabs-wrap">
        <div class="col-xs-12 pab10">
          <!-- <h3>{{ $t("default.APpromotions") }}</h3> -->
          <div class="tabs">
            <button
              @click="setActiveTab('current')"
              :class="{ active: activeTab === 'current' }"
            >
              {{ $t("default.APpromotions") }}
            </button>
            <button
              @click="setActiveTab('workshop')"
              :class="{ active: activeTab === 'workshop' }"
            >
              {{ $t("default.APpromotionsWorkshop") }}
            </button>
            <button
              v-if="
                appPromotionsArchiveSorted &&
                appPromotionsArchiveSorted.length > 0
              "
              @click="setActiveTab('archive')"
              :class="{ active: activeTab === 'archive' }"
            >
              {{ $t("default.APpromotionsArchive") }}
            </button>
          </div>
        </div>
      </div>

      <p v-if="loading" class="loading">{{ $t("default.loading") }}</p>
      <div v-else class="prminiarea prar-new prsize1">
        <div v-if="activeTab === 'current'" class="row">
          <div class="col-xs-12 pab30">
            <h3>
              {{ $t("default.APpromotions") }}
            </h3>
          </div>
          <PromotionNewItem
            v-for="promotion in appPromotionsSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
          <PromotionItem
            v-for="promotion in promotions"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-if="activeTab === 'workshop'" class="row promo-workshop">
          <div class="col-xs-12 pab30">
            <h3>
              {{ $t("default.APpromotionsWorkshop") }}
            </h3>
          </div>
          <PromotionNewItem
            v-for="promotion in appPromotionsWorkshopSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-if="activeTab === 'archive'" class="row promo-archive">
          <div class="col-xs-12 pab30">
            <h3>
              {{ $t("default.APpromotionsArchive") }}
            </h3>
          </div>
          <PromotionNewItem
            v-for="promotion in appPromotionsArchiveSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-if="noConnect" class="mat40 patb50 text-center">
          {{ $t("default.noData") }}
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PromotionItem from "@/components/PromotionItem";
import PromotionNewItem from "@/components/PromotionNewItem";
import { mapGetters, mapActions } from "vuex";
import { FETCH_PROMOTIONS } from "../store/actions.type";
import localforage from "localforage";

export default {
  components: {
    PromotionItem,
    PromotionNewItem,
  },
  data() {
    return {
      promotions: [],
      loading: false,
      noConnect: false,
      activeTab: "current",
    };
  },
  computed: {
    ...mapGetters([
      "isPopupStatuteOpen",
      "init",
      "myInfo",
      "app_promotions",
      "app_promotions_archive",
    ]),
    appPromotionsSorted() {
      if (this.app_promotions) {
        let data = JSON.parse(JSON.stringify(this.app_promotions));
        data = data.filter((item) => !item.for_workshops);
        return data.sort(
          (a, b) => Date.parse(b.active_to) - Date.parse(a.active_to)
        );
      }
      return [];
    },
    appPromotionsArchiveSorted() {
      if (this.app_promotions_archive) {
        let data = JSON.parse(JSON.stringify(this.app_promotions_archive));
        return data.sort(
          (a, b) => Date.parse(b.active_to) - Date.parse(a.active_to)
        );
      }
      return [];
    },
    appPromotionsWorkshopSorted() {
      if (this.app_promotions) {
        let data = JSON.parse(JSON.stringify(this.app_promotions));
        data = data.filter((item) => item.for_workshops);
        return data.sort(
          (a, b) => Date.parse(b.active_to) - Date.parse(a.active_to)
        );
      }
      return [];
    },
  },
  async created() {
    try {
      const value = await localforage.getItem("vuex");
      if (!value) {
        this.loading = true;
      }
    } catch (err) {
      // This code runs if there were any errors
      // eslint-disable-next-line no-console
      console.log(err);
    }
    this.load();
    this.loadActiveTab();
    this.setActiveTabFromHash(); // This will override the loaded tab if there's a hash in the URL
    window.addEventListener("hashchange", this.setActiveTabFromHash);
  },
  destroyed() {
    window.removeEventListener("hashchange", this.setActiveTabFromHash);
  },
  methods: {
    ...mapActions([
      FETCH_PROMOTIONS,
      "FETCH_NEW_PROMOTIONS",
      "FETCH_NEW_PROMOTIONS_ARCHIVE",
    ]),
    async load() {
      if (!this.init || !this.myInfo) {
        return;
      }

      try {
        this.promotions = await this.FETCH_PROMOTIONS();
        await this.FETCH_NEW_PROMOTIONS();
        await this.FETCH_NEW_PROMOTIONS_ARCHIVE();
      } catch (error) {
        this.noConnect = error;
      } finally {
        this.loading = false;
      }
    },
    saveActiveTab() {
      localStorage.setItem("apfilia-activeTab", this.activeTab);
    },
    loadActiveTab() {
      const savedTab = localStorage.getItem("apfilia-activeTab");
      if (savedTab && ["current", "workshop", "archive"].includes(savedTab)) {
        this.activeTab = savedTab;
      } else {
        this.activeTab = "current"; // Default tab
      }
    },
    setActiveTabFromHash() {
      const hash = window.location.hash.slice(1);
      if (["current", "workshop", "archive"].includes(hash)) {
        this.activeTab = hash;
        this.saveActiveTab();
      } else {
        this.loadActiveTab();
        this.updateHash();
      }
    },
    updateHash() {
      window.location.hash = this.activeTab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.saveActiveTab();
    },
  },
  watch: {
    init() {
      this.load();
    },
    myInfo() {
      this.load();
    },
    activeTab() {
      this.updateHash();
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 4px 12px;
  border: none;
  background-color: #fff;
  color: #0c315d;
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s;
  height: auto;
}

.tabs button.active {
  background-color: #0c315d;
  color: white;
  font-family: "Prompt", sans-serif;
  font-weight: 600;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

@media (max-width: 667px) {
  .tabs-wrap {
    background-image: radial-gradient(
      circle at 50% 53%,
      rgba(58, 88, 123, 0.8),
      #3a587b
    );
  }
  .tabs {
    flex-wrap: wrap;
  }

  .tabs button {
    width: 100%;
  }
}
</style>
