<template>
    <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="prminibox promoarea">
            <router-link :to="/promotions/+promotion.id">
                <div class="boxrou bbgra bbimg">
                    <div class="bbimg--bg">
                        <img :src="promotion | asPromotionImage" class="imgr" />
                    </div>
                    <div class="bbimg-desc">
                        <h5>{{ promotion.name }}</h5>
                        <p>{{ promotion.brief }}</p>
                    </div>
                </div>
            </router-link>
            <div class="join">
                <div><router-link :to="/promotions/+promotion.id">{{ $t("default.listOfparticipants") }}</router-link></div>
                <div>
                    <a v-if="promotion.contents.statute" @click="OPEN_POPUP_STATUTE(promotion.contents.statute)">{{ $t("default.statute") }}</a>
                    <span v-else>{{ $t("default.noPromotionRules") }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { OPEN_POPUP_STATUTE } from '../store/mutations.type'
import { mapMutations } from 'vuex'
export default {
    props: {
        promotion: {
            type: Object,
            required: true
        }
    },
    methods: {
        ...mapMutations([OPEN_POPUP_STATUTE]),
    }
}
</script>