<template>
  <div v-if="app_promotion" class="row equal">
    <div class="col-xs-12 col-md-6 col-lg-6" style="z-index: 1">
      <div class="vercenter">
        <h2 class="hs1">
          {{ app_promotion.translates[app_promotion.default_lang].name }}
        </h2>
        <p></p>
        <p class="small matb10" :title="app_promotion.active_to">
          {{ $t("default.endOfpromotion") }} {{ timeToEnd | asCounter }}.
        </p>
        <div class="buttons-wrap">
          <a
            href="#"
            v-if="app_promotion.translates[app_promotion.default_lang].statute"
            @click="
              OPEN_POPUP_STATUTE(
                app_promotion.translates[app_promotion.default_lang].statute
              )
            "
            class="abtn--c5 matb15"
            >{{ $t("default.seePromotionRegulations") }}</a
          >
          <a
            v-if="
              app_promotion.translates[app_promotion.default_lang].leaflet_file
            "
            target="_blank"
            :href="
              app_promotion.translates[app_promotion.default_lang].leaflet_file
            "
            class="abtn--c4 matb15"
            >{{ $t("default.leaflet") }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-5 col-lg-push-1 pat10">
      <div class="imgh3xx">
        <img
          v-if="app_promotion.image"
          :src="app_promotion.image"
          class="imgr"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { counter } from "../common/mixins";
import { OPEN_POPUP_STATUTE } from "../store/mutations.type";

export default {
  mixins: [counter],
  computed: {
    ...mapGetters(["app_promotion"]),
    timeToEnd() {
      return this.countTo(this.app_promotion.active_to);
    },
  },
  created() {
    this.countEvery(60);
  },
  methods: {
    ...mapMutations([OPEN_POPUP_STATUTE]),
  },
};
</script>

<style>
.buttons-wrap {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
</style>
