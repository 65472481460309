<style>
    table.header--myclients thead tr th:nth-child(1) { width: 135px; }
    table.header--myclients thead tr th:nth-child(2) { width: 70px; }
    table.header--myclients thead tr th:nth-child(3) { width: auto; }
    table.header--myclients thead tr th:nth-child(4) { width: 70px; }
    table.header--myclients thead tr th:nth-child(5) { width: 220px; }
</style>

<template>

    <main v-if="myBonuses" class="main2">

        <div class="container">

            <div class="row">
                <div class="col-xs-12 col-md-5">
                    <input type="text" class="form-control matb20" v-model="search" v-bind:minlength="3" :placeholder="$t('tables.search')" />
                </div>
                <div class="col-xs-12 col-md-7">
                    <pagination v-model="page" :total-page="pages" size="md" align="right" boundary-links/>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">

                    <Table
                        v-if="myBonuses.realisation_branches" 
                        class="rwdtable header--myclients" style="width:100%"
                        :items="myBonuses.realisation_branches" 
                        :headers="headers" 
                        :fields="fields" 
                        :search="search" 
                        :page.sync="page" 
                        :itemsPerPage="itemsPerPage" 
                        :pages.sync="pages"
                    />

                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <pagination v-model="page" :total-page="pages" size="md" align="right" boundary-links/>
                </div>
            </div>

        </div>





    </main>

</template>

<script>
import { mapGetters } from 'vuex'
import Table from '../components/Table'

export default {
    components: {
        Table,
    },
    data() {
        return {
            headers: [
                'Pozycja rankingu',
                'Nr filii',
                'Filia',
                'Grupa',
                '% real.tar.',
            ],
            fields: [
                'branch_position',
                'branch_ix',
                'branch_name',
                'branch_groupname', 
                'branch_percent'
            ],
            search: '',
            itemsPerPage: 25,
            page: 1,
            pages: 1,
        }
    },
    computed: {
        ...mapGetters(['myBonuses']),
        
    },
}




/*



                    <table id="example" class="rwdtable" style="width:100%">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Position</th>
                                <th>Office</th>
                                <th>Age</th>
                                <th>Start date</th>
                                <th>Salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr @click="openPopupClient">
                                <td data-collabel="Name">Tiger Nixon</td>
                                <td data-collabel="Position">System Architect dłuższa treść informacyjna</td>
                                <td data-collabel="">Edinburgh</td>
                                <td data-collabel="">61</td>
                                <td data-collabel="">2011/04/25</td>
                                <td data-collabel="">$320,800</td>
                            </tr>
                            <tr @click="openPopupClient">
                                <td data-collabel="Name">Garrett Winters</td>
                                <td data-collabel="Position">Accountant</td>
                                <td data-collabel="">Tokyo</td>
                                <td data-collabel="">63</td>
                                <td data-collabel="">2011/07/25</td>
                                <td data-collabel="">$170,750</td>
                            </tr>
                        </tbody>
                    </table>



*/


</script>