<style>
    h2 { margin-bottom:8px; }
    h4 { margin-bottom:8px; font-weight: bold; }
</style>

<template>
    <div v-if="myBonuses">
        <div class="row">
            <div class="col-xs-12 col-md-5">
                <div class="vercenter">
                    <h2 class="hs1">{{ $t("bonuses.title") }}</h2>
                </div>
            </div>
            <div class="col-xs-12 col-md-4">
                <h2>{{ $t("bonuses.information") }}:</h2>
                <p>{{ $t("bonuses.turnoverBranch") }}: <strong>{{ getCurrency0(myBonuses.realisation.amount) }}</strong></p>
                <hr />
                <h4>{{ $t("bonuses.bonus1") }}</h4>
                <p>{{ $t("bonuses.group") }}: <strong>{{ myBonuses.realisation.groupname }}</strong></p>
                <p>{{ $t("bonuses.position") }}: <strong>{{ myBonuses.realisation.position }}</strong></p>
                <p>{{ $t("bonuses.baseTarget") }}: <strong>{{ floorFigure(myBonuses.realisation.percent, 2) }}&nbsp;%</strong></p>
                <hr />
                <h4>{{ $t("bonuses.bonus2") }}</h4>
                <p>{{ $t("bonuses.impIndividualTarget") }}: <strong>{{ floorFigure(myBonuses.realisation.individual_percent, 2) }}&nbsp;%</strong></p>
                <p>{{ $t("bonuses.individualTarget") }}: <strong>{{ getCurrency0(myBonuses.realisation.individual_target) }}</strong></p>
                <br />
            </div>
            <div class="col-xs-12 col-md-3 pat50">
                <div v-if='myInfo.branch_account_type=="DF"' class="branchrankbox">
                    <svg class="circle-chart" viewBox="0 0 45 45" width="380" height="380" xmlns="http://www.w3.org/2000/svg">
                        <circle class="circle-chart__background" stroke="#D7F2F2" stroke-width="3.9" fill="none" cx="50%" cy="50%" r="15.91549431"  />     
                        <circle class="circle-chart__circle" stroke="#52ae30" stroke-width="4" :stroke-dasharray="floorFigure(myBonuses.realisation.percent)+',100'" stroke-linecap="round" fill="none" cx="50%" cy="50%" r="15.91549431" />
                        <text class="circle-chart__percent" fill="#FFFFFF" stroke-width="0" x="22" y="22" alignment-baseline="central" text-anchor="middle" font-size="8" transform="rotate(180 22 22.5)" >{{ floorFigure(myBonuses.realisation.percent) }}&nbsp;%</text>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['myInfo', 'myBonuses']),
    },
    methods: {
        floorFigure(val, decimals){
            if (!decimals) decimals = 1;
            var d = Math.pow(10,decimals);
            return (parseInt(val.replace(",", ".")*d)/d).toFixed(decimals);
        },
        getFixed1 (val) {
            return parseFloat(val.replace(",", ".")).toFixed(1)
        },
        getCurrency0 (val) {
            return Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumFractionDigits: 0 }).format( parseFloat(val.replace(",", ".")) )
        },
    },
}
</script>