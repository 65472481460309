import {AP_PROMOTIONS_NEW_ADDRESS} from '@/common/config'
import axios from 'axios' 

const apiClient = axios.create({
  baseURL: AP_PROMOTIONS_NEW_ADDRESS,
  timeout: 50000
})

// apiClient.defaults.headers['Accept-Language'] = 1
// apiClient.defaults.headers['League-Id'] = 5
// apiClient.defaults.headers['Authorization'] = `Bearer 6071|aUCzvSXQlUyaYFBKJH20odfc02MRAJyHASSxtirj`

export default apiClient