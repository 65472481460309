<style>
table.header--promotion thead tr th:nth-child(1) {
  width: 100px;
}
table.header--promotion thead tr th:nth-child(2) {
  width: auto;
}
table.header--promotion thead tr th:nth-child(3) {
  width: 170px;
}
table.header--promotion thead tr th:nth-child(4) {
  width: 70px;
}
table.header--promotion thead tr th:nth-child(5) {
  width: 240px;
}
</style>

<template>
  <main class="main2">
    <div v-if="loading == 1" class="container loader-wrapper">
      <div class="row">
        <div class="col-xs-12 text-center">
          <p class="loading2">{{ $t("default.loading") }}</p>
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading == 2" class="container">
      <div v-if="promotionClients.length" class="row">
        <div class="col-xs-12 col-md-4">
          <input
            type="text"
            class="form-control matb20"
            v-model="search"
            :placeholder="$t('tables.search')"
          />
        </div>
        <div class="col-xs-12 col-md-8">
          <div class="flexWrap">
            <div
              @click="download2"
              class="abtn--c4"
              style="margin-right: 16px; cursor: pointer"
            >
              {{ $t("default.download") }}
            </div>
            <pagination
              v-model="page"
              :total-page="pages"
              size="md"
              align="right"
              boundary-links
            />
          </div>
        </div>
      </div>

      <div v-if="promotionClients.length" class="row">
        <div class="col-xs-12">
          <Table
            class="rwdtable header--promotion"
            style="width: 100%"
            :items="promotionClients"
            :headers="headers"
            :fields="fields"
            :search="search"
            :page.sync="page"
            :itemsPerPage="itemsPerPage"
            :pages.sync="pages"
            @tr:clicked="OPEN_POPUP_CLIENT($event.id)"
          />
        </div>
      </div>

      <h4 v-if="!promotionClients.length" class="loading text-center">
        {{ $t("default.noData") }}
      </h4>
    </div>

    <PopupPromotionClient />
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Table from "../components/Table";
import PopupPromotionClient from "@/components/PopupPromotionClient";
import { FETCH_PROMOTION } from "../store/actions.type";
import { OPEN_POPUP_CLIENT } from "../store/mutations.type";
import exportFromJSON from "export-from-json";
import xlsx from "json-as-xlsx";

export default {
  components: {
    Table,
    PopupPromotionClient,
  },
  data() {
    return {
      loading: false,
      noConnect: false,
      fields: [
        "id",
        "client_firmname",
        "accepted_at",
        "client_branch",
        "client_branch_ph",
      ],
      search: "",
      itemsPerPage: 25,
      page: 1,
      pages: 1,
    };
  },
  computed: {
    ...mapGetters(["promotion", "isPopupClientOpen", "promotionClients"]),
    headers() {
      return [
        this.$i18n.t("tables.customerNumber"),
        this.$i18n.t("tables.name"),
        this.$i18n.t("tables.acceptanceRulesDate"),
        this.$i18n.t("tables.branch"),
        this.$i18n.t("tables.ph"),
      ];
    },
  },
  created() {
    this.loading = 1;
    this.load();
  },
  methods: {
    ...mapActions([FETCH_PROMOTION]),
    ...mapMutations([OPEN_POPUP_CLIENT]),
    async load() {
      try {
        await this.FETCH_PROMOTION(this.$route.params.id);
      } catch (error) {
        this.noConnect = error;
      }

      this.loading = 2;
    },
    download() {
      const reduceItems = this.fields.reduce((accumulator, element, index) => {
        return { ...accumulator, [element]: this.headers[index] };
      }, {});
      const data = this.promotionClients;
      const fields = reduceItems;
      const fileName = `${this.promotion.name.replace(/ /g, "_")}_${
        this.promotion.started_at.split(" ")[0]
      }_${this.promotion.ended_at.split(" ")[0]}`;
      const exportType = exportFromJSON.types.csv;

      exportFromJSON({ data, fileName, fields, exportType });
    },
    download2() {
      let data = [
        {
          sheet: ``,
          columns: [
            { label: this.$i18n.t("tables.customerNumber"), value: "id" },
            { label: this.$i18n.t("tables.name"), value: "client_firmname" },
            {
              label: this.$i18n.t("tables.acceptanceRulesDate"),
              value: "accepted_at",
            },
            { label: this.$i18n.t("tables.branch"), value: "client_branch" },
            { label: this.$i18n.t("tables.ph"), value: "client_branch_ph" },
          ],
          content: this.promotionClients,
        },
      ];

      let settings = {
        fileName: `${this.promotion.name.replace(/ /g, "_")}_${
          this.promotion.started_at.split(" ")[0]
        }_${this.promotion.ended_at.split(" ")[0]}`,
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
      };
      xlsx(data, settings);
    },
  },
};
</script>
