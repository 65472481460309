<template>
  <div v-if="isNotification" class="sticky sticky--bottom">
      <div
          class="prompt"
          :class="{ 'prompt--ios': showInstallMessageOnIOS }"
      >
          <div
              class="prompt__cancel-ios"
              @click.stop="dismiss"
          >
              <img
                  src="@/assets/gfx/cancel-icon.svg"
                  alt=""
              >
          </div>
          <div class="row w100">
                <div class="col-12">
                    <div
                        v-if="promptEvent"
                        class="d-flex"
                        @click="showPrompt"
                    >
                    <div class="prompt__icon">
                        <img
                            class=""
                            src="@/assets/gfx/logo-pap.png"
                            alt="Logo"
                        >
                      </div>
                        <p
                            class="prompt__description"
                            @click.prevent
                        >
                        {{ $t("pwa.info") }}
                        </p>
                    </div>
                    <div
                        v-else-if="showInstallMessageOnIOS"
                        class="prompt__ios-message"
                    >
                        <img
                            class="prompt__ios-logo"
                            src="@/assets/gfx/logo-pap.png"
                        >
                        <div>
                            <p class="prompt__ios-title">
                              {{ $t("pwa.info2") }}
                            </p>
                            <div class="d-flex">
                                <p>{{ $t("pwa.info3") }}</p>
                                <img src="@/assets/gfx/ios-share-icon.svg">
                            </div>

                            <div class="d-flex">
                                <p>{{ $t("pwa.info4") }}</p>
                                <img
                                    src="@/assets/gfx/ios-icon-add-app.svg"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                    <div v-else-if="isInstalledInfo">
                      {{ $t("pwa.info5") }}
                    </div>
                </div>
            </div>          
      </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { CLEAR_PROMPT_EVENT, SET_IS_ONLINE } from '@/store/mutations.type';
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      isRefreshing: false,
      registration: null,
      showInstallMessageOnIOS: false,
      isInstalledInfo: false,
    };
  },
  computed: {
    ...mapGetters(['promptEvent']),
    isNotification() {
      return (
        (!this.promptCookies
            && (this.promptEvent || this.showInstallMessageOnIOS))
        || this.isInstalledInfo
      );
    },
    promptCookies() {
      return (/true/).test(Cookies.get('prompt-pwa'));
    },
  },
  created() {
    // recognize ios devices.
    if (this.isSafari() && !this.isInStandaloneMode()) {
      this.showInstallMessageOnIOS = true;
    }
    document.addEventListener('serviceWorkerUpdated', (e) => this.refreshServiceWorker(e));
    window.addEventListener('appinstalled', () => {
        this.CLEAR_PROMPT_EVENT();
        this.showInfoAfterInstalled();
    });
    // Init network status.
    this.updateOnlineStatus();
    // Listener for changes network status.
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
    if ('serviceWorker' in navigator) {
      // Listener for adding new service worker.
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.isRefreshing) return;
        this.isRefreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    ...mapMutations([SET_IS_ONLINE, CLEAR_PROMPT_EVENT]),
    dismiss() {
      this.showInstallMessageOnIOS = null;
      this.CLEAR_PROMPT_EVENT();
    },
    updateOnlineStatus() {
      this.SET_IS_ONLINE(navigator.onLine);
    },
    refreshServiceWorker(event) {
      this.registration = event.detail;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
    showPrompt() {
      this.promptEvent.prompt();
    },
    showInfoAfterInstalled() {
      this.isInstalledInfo = true;
      setTimeout(() => {
          this.isInstalledInfo = false;
      }, 4000);
    },
    isInStandaloneMode() {
      return (
        'standalone' in window.navigator && window.navigator.standalone
      );
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
  },
};
</script>
<style scoped>
.prompt {
  background: #fff;
  color: #0c315d;
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: center;
}
.prompt-btn {
  margin-top: 15px;
  max-height: 20px;
  font-size: 14px;
  text-transform: none;
  padding: 10px;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 99999;
}
.sticky--bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: unset;
  text-align: center;
}
.prompt__icon {
  background-color: #0c315d;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.prompt__icon img {
  max-width: 100%;
  height: auto;
}
.prompt__close {
  margin-left: 10px;
  cursor: pointer;
}
.prompt__description {
  text-align: left;
  user-select: none;
}
.prompt__ios-message {
  line-height: 1.8;
  display: flex;
  font-size: 14px;
  text-align: left;
}
.prompt--ios {
  margin: 8px;
  border-radius: 4px;
  position: relative;
}
.prompt--ios:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: -3px;
  transform: rotate(45deg);
  background-color: #fff;
}
.prompt__ios-logo {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.prompt__ios-title {
  font-weight: bold;
}
.prompt__cancel-ios {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 24px;
  height: 24px;
  display: flex;
}
.d-flex {
  display: flex;
  align-items: center;
}
</style>