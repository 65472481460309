<style>
    h2 { margin-bottom:8px; }
    h4 { margin-bottom:8px; font-weight: bold; }
</style>

<template>
    <div>
        <div class="row">
            <div class="col-xs-12">
                <div class="vercenter">
                    <h2 class="hs1">{{ $t("home.bonusBranch") }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['myInfo']),
    },
    methods: {
        floorFigure(val, decimals){
            if (!decimals) decimals = 1;
            var d = Math.pow(10,decimals);
            return (parseInt(val.replace(",", ".")*d)/d).toFixed(decimals);
        },
        getFixed1 (val) {
            return parseFloat(val.replace(",", ".")).toFixed(1)
        },
        getCurrency0 (val) {
            return Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumFractionDigits: 0 }).format( parseFloat(val.replace(",", ".")) )
        },
    },
}
</script>