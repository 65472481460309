import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './common/filters'
import * as uiv from 'uiv'
import './registerServiceWorker'
import { SAVE_PROMPT_EVENT } from '@/store/mutations.type';
import i18n from './i18n';

Vue.config.productionTip = false

Vue.use(
  uiv
);

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  store.commit(SAVE_PROMPT_EVENT, e);
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
