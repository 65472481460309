<template>
    <header :class="$route.path == '/' || $route.path == '/external-promotions'  ? 'head1' : 'head2'">
        <div v-if="myInfo" class="container">
            <div class="row">
                <div class="col-xs-12 navmain" :class="{ 'open' : isBurgerOn }">
                    <div class="ap-logo">
                        <router-link to="/"><img src="@/assets/gfx/logo-pap.png"></router-link>
                    </div>
                    <div class="mobnav" @click="toggleBurgerOn">
                        <div class="ico-hamburger"><span></span><span></span><span></span><span></span></div>
                    </div>
                    <nav>
                        <div class="own">
                            <h3>{{ myInfo.branch_account_firstname }} <br/>{{ myInfo.branch_account_lastname }}</h3>
                            <p class="nowrap">{{ $t("account.branch") }}: {{ myInfo.branch_account_name }}</p>
                        </div>
                        <ul class="mnu">
                            <li><router-link to="/"><span @click="closeBurgerOn">{{ $t("default.APpromotions") }}</span></router-link></li><!--
							--><li><router-link to="/external-promotions"><span @click="closeBurgerOn">{{ $t("default.Externalpromotions") }}</span></router-link></li><!--
							--><li><router-link to="/my-clients"><span @click="closeBurgerOn">{{ $t("default.clients") }}</span></router-link></li><!--
							--><li><a @click="OPEN_POPUP_ACCOUNT(); closeBurgerOn()">{{ $t("default.account") }}</a></li>
                            <li v-if="$i18n.locale === 'pl'"><a @click="$i18n.locale = 'en'"><img src="@/assets/gfx/flag-en.jpg" /></a></li>
                            <li v-if="$i18n.locale === 'en'"><a @click="$i18n.locale = 'pl'"><img src="@/assets/gfx/flag-pl.jpg" /></a></li>
                        </ul>
                        <a :href="init.logout" class="nav--logout">{{ $t("default.logout") }}</a>
                    </nav>
                </div>
            </div>

            <router-view name="subheader"/>

        </div>
    </header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { CLOSE_POPUP_ACCOUNT, OPEN_POPUP_ACCOUNT } from '../store/mutations.type'

export default {
    data() {
        return {
            isBurgerOn: false
        }
    },
    computed: {
        ...mapGetters(['isPopupAccountOpen', 'init', 'myInfo']),
        isOpen: {
            get() {
                return this.isPopupAccountOpen
            },
            set() {
                this.$store.commit(CLOSE_POPUP_ACCOUNT)
            }
        }
    },
    methods: {
        ...mapMutations([OPEN_POPUP_ACCOUNT]),
        openBurgerOn() {
            this.isBurgerOn = true
            this.disableScroll()
        },
        toggleBurgerOn() {
            if(this.isBurgerOn) {
                this.closeBurgerOn()
            } 
            else { 
                this.openBurgerOn()
            }
        },
        closeBurgerOn() {
            this.isBurgerOn = false
            this.enableScroll()
        },
        disableScroll() {
            document.documentElement.style.overflow = 'hidden'
        },
        enableScroll() {
            document.documentElement.style.overflow = 'auto'
        }
    }
  }
</script>