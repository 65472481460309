<template>
    <modal v-model="isOpen" title="Statute" @show="onShow" ref="modal" id="modal-statute" size="lg" auto-focus>
        <div slot="title">{{ $t("default.promotionRules") }}</div>
        <p v-if="popupStatuteText" v-html="popupStatuteText"></p>
        <p v-else>{{ $t("default.noPromotionRules") }}</p>
        <div slot="footer">
            <btn @click="isOpen=false">{{ $t("default.close") }}</btn>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { CLOSE_POPUP_STATUTE } from '../store/mutations.type'

  export default {
    methods: {
      onShow() {
        // console.log('a')
      }
    },
    computed: {
        ...mapGetters(['isPopupStatuteOpen', 'popupStatuteText']),
        isOpen: {
            get() {
                return this.isPopupStatuteOpen
            },
            set() {
                this.$store.commit(CLOSE_POPUP_STATUTE)
            }
        }
    }
  }
</script>