<style>
    table thead th { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size:13px; line-height: 1; cursor: pointer; user-select: none; }
    table thead th > i { position: absolute; font-size:13px !important; transform: translateY(1px) translateX(2px); }
    table tbody tr { cursor: pointer; }
</style>

<template>
    <table>
        <thead>
            <tr>
                <th v-for="(header, idx) in headers" v-bind:key="idx" @click="sort(idx)">
                    {{ header }} 
                    <i v-if="idx==sortIdx" :class="sortRev ? 'fa-sort-desc' : 'fa-sort-asc'" class="fa" />
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in displayItems" v-bind:key="item.id" @click="$emit('tr:clicked', item)">
                <td v-for="(field, idx) in fields" v-bind:key="idx" :data-collabel="headers[idx]">
                    {{ item[field] }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import _ from 'lodash'

export default {
    props: {
        items: Array,
        search: String,
        fields: Array,
        headers: Array,
        page: Number,
        itemsPerPage: Number,
        pages: Number,
        delay: {
            type: Number,
            default: 200,
            required: false,
        },
        defaultSort: Object,
    }, 
    data() {
        return {
            sortIdx: 0,
            sortRev: false,
        }
    },
    computed: {
        displayItems() {
            return this.paginateItems(this.sortItems(this.searchItems(this.items)));
        },
    },
    methods: {
        sort(idx) {
            this.sortRev = this.sortIdx == idx ? !this.sortRev : false;
            this.sortIdx = idx;
        },
        sortItems(items) {
            const field = this.fields[this.sortIdx];
            let sortedItems = _.sortBy(items, item => item[field]);
            return this.sortRev ? _.reverse(sortedItems) : sortedItems;  
        },
        /*

        searchItems(items) {
            // clear timeout variable
            clearTimeout(this.timeout);

            const search = this.search.replace(/\s+/g, ' ').trim()

            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.

                if( !search || search.length<=3 ) {
                    return this.items;
                } else {
                    const tokens = search.split(' ');
                    return _.filter(items, item => {
                        const matchedFields = _.filter(this.fields, field => {
                            const matchedTokens = _.filter(tokens, token => {
                                const pattern = new RegExp(token, 'i')
                                return String(item[field]).search(pattern) > -1
                            })
                            return matchedTokens.length == tokens.length
                        })
                        return matchedFields.length
                    })
                }
            }, 1000);
        },

        */
       
        searchItems(items) {
            const search = this.search.replace(/\s+/g, ' ').trim()

            if( !search || search.length<=3 ) {
                return this.items;
            } else {
                const tokens = search.split(' ');
                return _.filter(items, item => {
                    const matchedFields = _.filter(this.fields, field => {
                        const matchedTokens = _.filter(tokens, token => {
                            const pattern = new RegExp(token, 'i')
                            return String(item[field]).search(pattern) > -1
                        })
                        return matchedTokens.length == tokens.length
                    })
                    return matchedFields.length
                })
            }
        },
        paginateItems(items) {
            this.$emit('update:pages', _.ceil(items.length / this.itemsPerPage))
            const skip = (this.page - 1) * this.itemsPerPage;
            return _.slice(items, skip, skip + this.itemsPerPage);
        },
    },
    watch: {
        search() {
            this.$emit('update:page', 1)
        }
    },
    created() {
        if (this.defaultSort) {
            this.sortIdx = this.defaultSort.sortIdx
            this.sortRev = this.defaultSort.sortRev
        }
    }
}
</script>