import Vue from "vue";
import i18n from "../i18n";
import { AP_PROMOTIONS_ADDRESS } from "../common/config";
import moment from "moment";

Vue.filter(
  "asPromotionImage",
  (promotion) => AP_PROMOTIONS_ADDRESS + "/" + promotion.images[0].filename
);

Vue.filter("asCounter", (time) => {
  if (time <= 0) return i18n.t("default.cTUp");

  const duration = moment.duration(time, "seconds");
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  //   const seconds = duration.seconds();

  let result = "";
  if (days > 0) result += `${days} ${i18n.t("default.cDays")} `;
  if (hours > 0 || days > 0) result += `${hours} ${i18n.t("default.cHours")} `;
  if (minutes > 0 || hours > 0 || days > 0)
    result += `${minutes} ${i18n.t("default.cMin")} `;
  //   result += `${seconds} ${i18n.t("default.cSec")}`;

  return result.trim();
});
