import moment from "moment";

export const counter = {
  data() {
    return {
      now: moment().unix(),
    };
  },
  methods: {
    countEvery(seconds) {
      setInterval(() => (this.now = moment().unix()), seconds * 1000);
    },
    countTo(endDate) {
      const end = moment(endDate);
      const now = moment(this.now, "X");

      return end.diff(now, "seconds");
    },
  },
};
