<template>
  <div class="row equal">
      <div class="col-xs-12 col-md-6 col-lg-5" style="z-index:1;">
          <div class="vercenter topimgpromo">
              <h2 class="hs1">3 SKOKI PO NAGRODY</h2>
              <p></p>
              <p class="small matb10">{{ $t('default.endOfpromotion') }} {{ timeToEnd | asCounter }}.</p>
              <p>&nbsp;</p>
          </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-7 pat10">
          <div class="imgh3xx">
              <img src="@/assets/gfx/ap-filia-3spn.jpg" class="imgr" />
          </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {counter} from '../common/mixins'

export default {
  mixins: [counter],
  computed: {
      ...mapGetters(['spn3']),
      timeToEnd() {
          return this.countTo("2023-12-31 23:59:59")
      }
  },
  created() {
      this.countEvery(60);
  },
  methods: {
      
  }
}
</script>