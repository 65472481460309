<style>
table.header--myclients thead tr th:nth-child(1) {
  width: 95px;
}
table.header--myclients thead tr th:nth-child(2) {
  width: 125px;
}
table.header--myclients thead tr th:nth-child(3) {
  width: auto;
}
table.header--myclients thead tr th:nth-child(4) {
  width: 60px;
}
table.header--myclients thead tr th:nth-child(5) {
  width: 55px;
}
table.header--myclients thead tr th:nth-child(6) {
  width: 55px;
}
table.header--myclients thead tr th:nth-child(7) {
  width: 240px;
}
</style>

<template>
  <main class="main2">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-4">
          <input
            type="text"
            class="form-control matb20"
            v-model="search"
            v-bind:minlength="3"
            :placeholder="$t('tables.search')"
          />
        </div>
        <div class="col-xs-12 col-md-8">
          <div class="flexWrap">
            <div
              @click="download2"
              class="abtn--c4"
              style="margin-right: 16px; cursor: pointer"
            >
              {{ $t("default.download") }}
            </div>
            <pagination
              v-model="page"
              :total-page="pages"
              size="md"
              align="right"
              boundary-links
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <Table
            v-show="myPpClientsWithProtocols"
            class="rwdtable header--myclients"
            style="width: 100%"
            :items="myPpClientsWithProtocols"
            :headers="headers"
            :fields="fields"
            :search="search"
            :page.sync="page"
            :itemsPerPage="itemsPerPage"
            :pages.sync="pages"
            @tr:clicked="OPEN_POPUP_CLIENT($event.client_ix)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <pagination
            v-model="page"
            :total-page="pages"
            size="md"
            align="right"
            boundary-links
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Table from "../components/Table";
import { OPEN_POPUP_CLIENT } from "../store/mutations.type";
import xlsx from "json-as-xlsx";
import _ from "lodash";

export default {
  components: {
    Table,
  },
  data() {
    return {
      fields: [
        "client_ix",
        "client_nip",
        "client_firmname",
        "client_branch",
        "has_account",
        "is_maxserwis",
        "client_branch_ph",
        "protocols",
      ],
      search: "",
      itemsPerPage: 25,
      page: 1,
      pages: 1,
    };
  },
  computed: {
    ...mapGetters([
      "myPpClients",
      "isPopupClientOpen",
      "protocols",
      "app_users",
    ]),
    headers() {
      return [
        this.$i18n.t("tables.customerNumber"),
        this.$i18n.t("tables.NIP"),
        this.$i18n.t("tables.name"),
        this.$i18n.t("tables.branch"),
        this.$i18n.t("tables.PPr"),
        this.$i18n.t("tables.maX"),
        this.$i18n.t("tables.ph"),
        this.$i18n.t("tables.protocols"),
      ];
    },
    // myPpClientsWithProtocols() {
    //   return this.myPpClients.map((client) => {
    //     return {
    //       ...client,
    //       protocols: this.protocols[client.client_ix]
    //         ? this.protocols[client.client_ix].length
    //         : 0,
    //     };
    //   });
    // },
    myPpClientsWithProtocols() {
      return this.processClients(this.myPpClients);
    },
  },
  watch: {
    // Clear the memoized cache when protocols change
    protocols() {
      this.processClients.cache.clear();
    },
    // Clear the memoized cache when myPpClients change
    myPpClients() {
      this.processClients.cache.clear();
    },
  },
  methods: {
    ...mapMutations([OPEN_POPUP_CLIENT]),
    processClients: _.memoize(
      function (clients) {
        return clients.map((client) => ({
          ...client,
          protocols: this.protocols[client.client_ix]
            ? this.protocols[client.client_ix].length
            : 0,
        }));
      },
      function (clients) {
        // This is the resolver function for memoization
        // It creates a cache key based on client IDs and the protocols object
        return `${clients.length}-${Object.keys(this.protocols).join(",")}`;
      }
    ),
    download2() {
      let data = [
        {
          sheet: ``,
          columns: [
            {
              label: this.$i18n.t("tables.customerNumber"),
              value: "client_ix",
            },
            { label: this.$i18n.t("tables.NIP"), value: "client_nip" },
            { label: this.$i18n.t("tables.name"), value: "client_firmname" },
            { label: this.$i18n.t("tables.branch"), value: "client_branch" },
            { label: this.$i18n.t("tables.PPr"), value: "has_account" },
            { label: this.$i18n.t("tables.maX"), value: "is_maxserwis" },
            { label: this.$i18n.t("tables.ph"), value: "client_branch_ph" },
            { label: this.$i18n.t("tables.protocols"), value: "protocols" },
          ],
          content: this.myPpClientsWithProtocols,
        },
      ];

      let settings = {
        fileName: `Klienci`,
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
      };
      xlsx(data, settings);
    },
  },
};

/*



                    <table id="example" class="rwdtable" style="width:100%">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Position</th>
                                <th>Office</th>
                                <th>Age</th>
                                <th>Start date</th>
                                <th>Salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr @click="openPopupClient">
                                <td data-collabel="Name">Tiger Nixon</td>
                                <td data-collabel="Position">System Architect dłuższa treść informacyjna</td>
                                <td data-collabel="">Edinburgh</td>
                                <td data-collabel="">61</td>
                                <td data-collabel="">2011/04/25</td>
                                <td data-collabel="">$320,800</td>
                            </tr>
                            <tr @click="openPopupClient">
                                <td data-collabel="Name">Garrett Winters</td>
                                <td data-collabel="Position">Accountant</td>
                                <td data-collabel="">Tokyo</td>
                                <td data-collabel="">63</td>
                                <td data-collabel="">2011/07/25</td>
                                <td data-collabel="">$170,750</td>
                            </tr>
                        </tbody>
                    </table>



*/
</script>
