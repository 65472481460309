<template>
<div>
    <modal v-if="isOpen" v-model="isOpen" title="Client" @show="onShow" ref="modal" id="modal-client" size="lg" auto-focus>
        <div slot="title">{{ $t("client.client") }}</div>
        <p>Popup PROMOTION CLIENT</p>
        <p>{{ $t("client.email") }}: {{ popupPromotionClient.client_email }}</p>
        <div slot="footer">
            <btn @click="isOpen=false">{{ $t("default.close") }}</btn>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CLOSE_POPUP_PROMOTION_CLIENT } from '../store/mutations.type'

export default {
    methods: {
      onShow() {
          console.log('promotion client')
      }
    },
    computed: {
        ...mapGetters(['popupPromotionClient', 'isPopupPromotionClientOpen']),
        isOpen: {
            get() {
                return this.isPopupPromotionClientOpen
            },
            set() {
                this.$store.commit(CLOSE_POPUP_PROMOTION_CLIENT)
            }
        }
    }
}
</script>